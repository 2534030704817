import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/user.data.models';
import { Profile } from '../models/profile.models';
import { Phone } from '../models/phone.models';
import { Country } from '../models/country.models';
import { Localization } from '../models/localization.models';
import { Bank } from '../models/bank.models';
import { Property } from '../models/property.models';
import { Amenity } from '../models/amenity.models';
import { DataType } from '../models/data.type.models';
import { Coupon } from '../models/coupon.models';

@Injectable({ providedIn: 'root' })
export class YampiService {
    user: User;
    countryList: Array<Country>;
    baseUrl = environment.baseUrl;
    token = JSON.parse(this.cookieService.getCookie('currentUser'));

    headers = new HttpHeaders().set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${this.token.token}`);

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUserData'));
        }
        return this.user;
    }

    /**
     * Returns the list country spinner
     */
    public listCountry(): Array<Country> {
        if (!this.countryList) {
                this.countryList = JSON.parse(this.cookieService.getCookie('countryList'));
        }
        return this.countryList;
    }

    /**
     * Returns the current user data
     */
    public currentUserData(): User {
        return this.user = JSON.parse(this.cookieService.getCookie('currentUserData'));
    }

    /**
     * Get user-data (Obtener info del usuario GET/user-data)
     */
    UserData() {
        return this.http.get<any>(this.baseUrl + '/user-data', {headers: this.headers})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user) {
                    this.user = user;
                    // store user details and jwt in cookie
                    this.cookieService.setCookie('currentUserData', JSON.stringify(user), 1);
                }
                return user;
            }));
    }


    /**
     * Get country (Obtener países GET/country)
     */
    getCountry() {
        return this.http.get<any>(this.baseUrl + '/country', {headers: this.headers})
            .pipe(map(countryList => {
                // login successful if there's a jwt token in the response
                if (countryList) {
                    this.countryList = countryList;
                    // store user details and jwt in cookie
                    this.cookieService.setCookie('countryList', JSON.stringify(countryList), 1);
                }
                return countryList;
            }));
    }

    /**
     * Get admin/media (Obtener lista filtrada de archivos adjuntos de YAMPI GET/admin/media)
     */
    adminMediaFilters(isChecked: number, isPrivate: boolean, q: string, limit: number) {
        return this.http.get<any>(this.baseUrl + `/admin/media?is_checked=${isChecked}&is_private=${isChecked}
                                                    &q=${q}&limit=${limit}`, {headers: this.headers});
    }

    /**
     * Get admin/media (Obtener lista de archivos adjuntos de YAMPI GET/admin/media)
     */
    adminMedia(params, page = 1) {
        return this.http.get<any>(this.baseUrl + `/admin/media?page=${page}`, {headers: this.headers, params});
    }

    /**
     * Get downloadFile
     * @param paht  Rutal del Archivo
     */
    getFile(paht: string) {
        return this.http.get<any>(paht, {headers: this.headers, responseType: 'blob' as 'json'})
            .pipe(map(adminMedia => {
                    this.createFile(adminMedia, 'application/pdf');
                    return adminMedia;
            }));
    }

    /**
     * Get downloadFile image
     * @param paht  Rutal del Archivo
     */
    getImage(paht: string) {
        return this.http.get<any>(paht, {headers: this.headers, responseType: 'blob' as 'json'})
            .pipe(map(adminMedia => {
                    return adminMedia;
            }));
    }

    /**
     * Get downloadFile
     * @param id  Identificador del documento
     */
    checkDocument(id: string, params: any) {
        return this.http.patch<any>(`${this.baseUrl}/admin/media/${id}`, params, {headers: this.headers});
    }

    /**
     * Crear documento
     * @param data  Documento
     * @param type  Tipo de documento
     */
    createFile(data: any, type: string) {
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }
    }

    /**
     * Buscar ciudad
     *
     * @param params Nombre ciudad
     */
    getGeoLocationList(params): Observable<any> {
        return this.http.get(this.baseUrl + '/location', {headers: this.headers, params});
    }

    /**
     * Buscar ciudad
     *
     * @param locationId Identificador de la ciudad
     */
    getGeoLocation(locationId: number): Observable<any> {
        return this.http.get(this.baseUrl + '/location/' + locationId, {headers: this.headers});
    }

    /**
     *  Obtner datos del perfil
     */
    getProfile(): Observable<any> {
        return this.http.get(this.baseUrl + '/profile', {headers: this.headers});
    }

    /**
     *  Actualizar datos del perfil
     *
     * @param profile Informacion del perfil
     */
    putProfile(profile: Profile): Observable<any> {
        return this.http.put(this.baseUrl + '/profile', profile, {headers: this.headers});
    }

    /**
     *  Obtener datos del telefono
     *
     * @param entityId Identificador de la entidad
     * @param entityName Nombre de la entidad
     */
    getPhone(entityId: number, entityName: string): Observable<any> {
        return this.http.get(this.baseUrl + `/phone/${entityId}/${entityName}`, {headers: this.headers});
    }

    /**
     *  Actualizar datos del telefono
     *
     * @param phone Informacion del telefono
     */
    putPhone(phone: Phone, entityId: number, entityName: string): Observable<any> {
        return this.http.put(this.baseUrl + `/phone/${entityId}/${entityName}`, phone, {headers: this.headers});
    }

    /**
     *  Agregar datos del telefono
     *
     * @param phone Informacion del telefono
     */
    postPhone(phone: Phone, entityId: number, entityName: string): Observable<any> {
        return this.http.post(this.baseUrl + `/phone`, phone, {headers: this.headers});
    }

    /**
     *  Obtner datos del localizacion
     *
     * @param entityId Identificador de la entidad --0
     * @param entityName Nombre de la entidad
     */
    getLocalization(entityId: number, entityName: string): Observable<any> {
        return this.http.get(this.baseUrl + `/localization/${entityId}/${entityName}`, {headers: this.headers});
    }

    /**
     *  Actualizar datos del localizacion
     *
     * @param localization Informacion de la localizacion
     */
    putLocalization(localization: Localization, entityId: number, entityName: string): Observable<any> {
        return this.http.put(this.baseUrl + `/localization/${entityId}/${entityName}`, localization, {headers: this.headers});
    }

    /**
     *  Obtener lista de bancos
     *
     */
    getBanks(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/bank?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener banco por id
     *
     * @param bankId Identificador del banco
     */
    getBank(bankId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/bank/${bankId}`, {headers: this.headers});
    }


    /**
     *  Crear bancos
     *
     * @param bank Informacion del banco
     */
    postBank(bank: Bank): Observable<any> {
        return this.http.post(this.baseUrl + `/admin/bank`, bank, {headers: this.headers});
    }

    /**
     *  Editar bancos
     *
     * @param bank Informacion del banco
     */
    putBank(bank: Bank): Observable<any> {
        return this.http.put(this.baseUrl + `/admin/bank/${bank.id}`, bank, {headers: this.headers});
    }

    /**
     *  Eliminar banco por id
     *
     * @param bankId Identificador del banco
     */
    deleteBank(bankId): Observable<any> {
        return this.http.delete(this.baseUrl + `/admin/bank/${bankId}`, {headers: this.headers});
    }

    /**
     *  Listar cuentas bancarias
     *
     * @param query Filtro
     */
    putBankAccountList(query, page = 1): Observable<any> {
        return this.http.put(this.baseUrl + `/admin/bank-account?page=${page}`, query, {headers: this.headers});
    }

    /**
     *  Listar cuentas bancarias
     *
     * @param query Filtro
     */
    putBankAccount(bankAccountId, status): Observable<any> {
        return this.http.put(this.baseUrl + `/admin/bank-account/${bankAccountId}`, status, {headers: this.headers});
    }

    /**
     *  Obtener cuenta bancaria por id
     *
     * @param bankId Identificador de la cuenta bancaria
     */
    getBankAccount(bankAccountId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/bank-account/${bankAccountId}`, {headers: this.headers});
    }

    /**
     *  Obtener preguntas para validar Usuario
     *
     */
    getFormValidateUser(): Observable<any> {
        return this.http.get(this.baseUrl + `/validate-user/1`, {headers: this.headers});
    }

    /**
     *  Enviar respuestas de validacion
     *
     */
    putFormValidateUser(answers): Observable<any> {
        return this.http.put(this.baseUrl + `/evaluate-response/1`, answers, {headers: this.headers});
    }


    /**
     *  Obtener lista de Tareas pendientes
     *
     */
    getTasks(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/jobs?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener listas de Tareas falladas
     *
     */
    getFailedTasks(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/failed-jobs?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener listas de Propiedades
     *
     */
    getProperties(params, page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/property?page=${page}`, {headers: this.headers, params});
    }

    /**
     *  Obtener listas de Tipos de Propiedades
     *
     */
    getPropertyType(): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/property-type`, {headers: this.headers});
    }

    /**
     *  Obtener propiedad por id
     *
     * @param propertyId Identificador de la propiedad
     */
    getProperty(propertyId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/property/${propertyId}`, {headers: this.headers});
    }

    /**
     *  Editar propiedad
     *
     * @param property Informacion de la propiedad
     */
    putProperty(property: Property): Observable<any> {
        return this.http.put(this.baseUrl + `/admin/property/${property.id}`, property, {headers: this.headers});
    }

    /**
     *  Obtener listas de Comodidades
     *
     */
    getAmenities(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/amenity?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener listas de Tipos de Comodidades
     *
     */
    getAmenityType(): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/amenity-type`, {headers: this.headers});
    }

    /**
     *  Crear comodidades
     *
     * @param amenity Informacion de la comodidad
     */
    postAmenity(amenity: Amenity, entityId: string, entityName: string): Observable<any> {
        return this.http.post(this.baseUrl + `/admin/amenity/${entityId}/${entityName}`, amenity, {headers: this.headers});
    }

    /**
     *  Obtener comodidad por id
     *
     * @param amenityId Identificador del amenity
     */
    getAMenity(amenityId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/amenity/${amenityId}`, {headers: this.headers});
    }

    /**
     *  Editar comodidad
     *
     * @param property Informacion de la comodidad
     */
    putAmenity(amenity: Amenity): Observable<any> {
        return this.http.put(this.baseUrl + `/admin/amenity/${amenity.id}`, amenity, {headers: this.headers});
    }

    /**
     *  Obtener lista de Ticket
     *
     */
    getTickets(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/ticket?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener Ticket por id
     *
     * @param ticketId Identificador del Ticket
     */
    getTicket(ticketId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/ticket/${ticketId}`, {headers: this.headers});
    }

    /**
     *  Eliminar Ticket por id
     *
     * @param ticketId Identificador del Ticket
     */
    deleteTicket(ticketId): Observable<any> {
        return this.http.delete(this.baseUrl + `/admin/ticket/${ticketId}`, {headers: this.headers});
    }

    /**
     *  Obtener lista de Vehiculos
     *
     */
    getVehicles(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/vehicle?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener lista de marcas de Vehiculos
     *
     */
    getVehiclesBrand(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/vehicle-brand?page=${page}`, {headers: this.headers});
    }

    /**
     *  Crear marca vehiculo
     *
     * @param vehicleBrand Informacion de la marca vehiculo
     */
    postVehicleBrand(vehicleBrand: DataType): Observable<any> {
        return this.http.post(this.baseUrl + `/admin/vehicle-brand`, vehicleBrand, {headers: this.headers});
    }

    /**
     *  Editar marca vehiculo
     *
     * @param vehicleBrand Informacion de la marca vehiculo
     */
    putVehicleBrand(vehicleBrand: DataType): Observable<any> {
        return this.http.put(this.baseUrl + `/admin/vehicle-brand/${vehicleBrand.id}`, vehicleBrand, {headers: this.headers});
    }

    /**
     *  Eliminar Marca de Vehiculo por id
     *
     * @param vehicleBrandId Identificador de la Marca de Vehiculo
     */
    deleteVehicleBrand(vehicleBrandId): Observable<any> {
        return this.http.delete(this.baseUrl + `/admin/vehicle-brand/${vehicleBrandId}`, {headers: this.headers});
    }

    /**
     *  Obtener Marca de Vehiculo por id
     *
     * @param bankId Identificador de la Marca de Vehiculo
     */
    getVehicleBrand(vehicleBrandId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/vehicle-brand/${vehicleBrandId}`, {headers: this.headers});
    }

    /**
     *  Obtener lista de tipos de Vehiculos
     *
     */
    getVehiclesTipe(): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/vehicle-type`, {headers: this.headers});
    }

    /**
     *  Crear tipo vehiculo
     *
     * @param vehicleType Informacion del tipo vehiculo
     */
    postVehicleType(vehicleType: DataType): Observable<any> {
        return this.http.post(this.baseUrl + `/admin/vehicle-type`, vehicleType, {headers: this.headers});
    }

    /**
     *  Eliminar Tipo de Vehiculo por id
     *
     * @param vehicleTypeId Identificador del Tipo de Vehiculo
     */
    deleteVehicleType(vehicleTypeId): Observable<any> {
        return this.http.delete(this.baseUrl + `/admin/vehicle-type/${vehicleTypeId}`, {headers: this.headers});
    }

    /**
     *  Crear Cupon
     *
     * @param coupon Informacion del Cupon
     */
    postCoupon(coupon: Coupon): Observable<any> {
        return this.http.post(this.baseUrl + `/admin/coupon`, coupon, {headers: this.headers});
    }

    /**
     *  Obtener lista de Pagos
     *
     */
    getPayments(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/shopping?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener Pagos por id
     *
     * @param paymentId Identificador del Pago
     */
    getPayment(paymentId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/shopping/${paymentId}`, {headers: this.headers});
    }

    /**
     *  Eliminar Pago por id
     *
     * @param vehicleTypeId Identificador del Pago
     */
    deletePayment(paymentId): Observable<any> {
        return this.http.delete(this.baseUrl + `/admin/shopping/${paymentId}`, {headers: this.headers});
    }

    /**
     *  Obtener lista de Contratos
     *
     */
    getContracts(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/contract?page=${page}`, {headers: this.headers});
    }

    /**
     *  Obtener Contrato por id
     *
     * @param contractId Identificador del Contrato
     */
    getContract(contractId): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/contract/${contractId}`, {headers: this.headers});
    }

    /**
     *  Obtener Contrato por id
     *
     * @param contractId Identificador del Contrato
     */
    deleteContract(contractId): Observable<any> {
        return this.http.delete(this.baseUrl + `/admin/contract/${contractId}`, {headers: this.headers});
    }

    /**
     *  Obtener listas de Tabajadores
     *
     */
    getWorkers(page = 1): Observable<any> {
        return this.http.get(this.baseUrl + `/admin/user?page=${page}`, {headers: this.headers});
    }

    /**
     *  Crear un trabajador
     *
     * @param worker Informacion del Trabajador
     */
    postWorker(worker): Observable<any> {
        return this.http.post(this.baseUrl + `/admin/user`, worker, {headers: this.headers});
    }

    /**
     *  Obtener listas de Tipos de documentos
     *
     */
    getDocIdType(): Observable<any> {
        return this.http.get(this.baseUrl + `/docid-type`, {headers: this.headers});
    }

}