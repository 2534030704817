import { Component } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public translateService: TranslateService) {
    translateService.setDefaultLang('en');
    const browserLang = translateService.getBrowserLang();
    // translateService.use(browserLang.match(/en|es/) ? browserLang : 'en');
    translateService.use('es');
  }

}
