import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        const otl = route.queryParams.otl;
        if (otl) {
            await this.authenticationService.loginOneTime(otl).subscribe(
                                    data => {
                                        this.router.navigate([route.queryParams.returnUrl || '/'], {});
                                        return true;
                                    },
                                    error => {
                                        return false;
                                    });
        } else if (currentUser) {
                // logged in so return true
                return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}