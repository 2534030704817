import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { YampiService } from '../../../core/services/yampi.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';
import { from } from 'rxjs';
import { User } from '../../../core/models/user.data.models';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],

})
export class LeftsidebarComponent implements OnInit {

  user: User;  
  baseUrl = environment.baseUrl;
  urlImage: string;

  @Input() sidebarType: string;

  constructor(private router: Router, private authenticationService: AuthenticationService, private yampiService: YampiService) { }

  ngOnInit() {
    this.yampiService.UserData()
      .subscribe(
        data => {
          this.user = data;
          if(this.user.portrait === null || this.user.portrait === undefined) {
            this.urlImage = 'assets/images/users/avatar-7.jpg';
          } else {
            this.urlImage = `${this.baseUrl}/${this.user.portrait.filepath}${this.user.portrait.filename}`;
          }
        },
        error => {
          console.log(error);
        });
  }

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: '/' } });
  }
}
